<script>
  import { call } from 'vuex-pathify'
  import { ApiError } from '@/api'
  import AddressForm from '@/components/account/AddressForm'

  export default {
    components: {
      AddressForm,
    },
    data() {
      return {
        formRef: null,
      }
    },
    methods: {
      ...call('account', ['addAddress']),
      async submit(address) {
        try {
          await this.formRef.process(this.addAddress(address))
          this.$navigate({ name: 'AccountProfileSettings' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <AddressForm v-ref="formRef" @submit="submit($event)">
      New Shipping Address
      <template v-slot:buttonLabel>Save Address</template>
    </AddressForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSettings' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
