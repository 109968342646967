<script>
  import { get, sync, call } from 'vuex-pathify'
  import debounce from 'lodash.debounce'
  import pick from '@/helpers/pick'
  import { SHIPPING_ADDRESS_NEW } from '@/store/subscribe'
  import screen from '@/helpers/screen'
  import env from '@/helpers/env'

  const requiredFields = [
    'firstName',
    'lastName',
    'address1',
    'city',
    'zipCode',
    'stateId',
    'countryId',
  ]

  export default {
    props: {
      editAddress: {
        type: Object,
        required: false,
        default: () => ({
          firstName: '',
          lastName: '',
          address1: '',
          address2: '',
          city: '',
          zipCode: '',
          countryId: null,
          stateId: null,
        }),
      },
      /** Whether there is a dropdown to select an existing address */
      selectOption: {
        type: Boolean,
        default: false,
      },
      /** Whether the CTA button at the bottom of the form should be hidden */
      hideButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        formRef: null,
        address: pick(this.editAddress, [
          'id',
          'firstName',
          'lastName',
          'address1',
          'address2',
          'city',
          'zipCode',
          'stateId',
          'countryId',
        ]),
        suggestedAddresses: [],
        autocompleteDropdownOpen: false,
        highlightIndex: 0,
        address1RefFocused: false,
        observedAutocompleteFlag: false,
      }
    },
    computed: {
      ...get('config', ['countries']),
      ...get('feature', ['flags']),
      ...get('account', ['addresses']),
      ...sync('subscribe', ['selectedShippingAddressId']),
      screen,
      isAddressAutocompleteEnabled() {
        return this.flags['address-autocomplete'] ?? false
      },
      allRequiredFieldsFilledOut() {
        return requiredFields.every((field) => this.address[field])
      },
      selectedCountry() {
        return this.countries?.find((country) => country.id === this.address.countryId)
      },
      selectedCountryIso() {
        return this.selectedCountry?.iso
      },
      countryOptions() {
        return this.countries?.map((country) => ({
          label: country.name,
          value: country.id,
        }))
      },
      stateOptions() {
        return this.selectedCountry?.states?.map((state) => ({
          label: state.name,
          value: state.id,
        }))
      },
      shippingAddressOptions() {
        const addresses = this.addresses.map((address) => ({
          label: this.$formatAddress(address, true),
          value: address.id,
        }))
        addresses.push({
          label: 'Enter a New Address',
          value: SHIPPING_ADDRESS_NEW,
        })
        return addresses
      },
      hasValidationErrors() {
        const validation = this.formRef?.validationProviders
          ? Object.values(this.formRef?.validationProviders).filter(
              (provider) => provider?.failedRules && Object.keys(provider.failedRules).length > 0
            ).length
          : 0
        return validation
      },
      zipCode() {
        return this.address?.zipCode
      },
      hasSuggestedAddresses() {
        return this.suggestedAddresses?.length > 0
      },
    },
    watch: {
      hasValidationErrors() {
        if (this.hasValidationErrors <= 0) {
          this.processForm()
        }
      },
      zipCode() {
        if (this.zipCode) {
          this.processForm()
        }
      },
    },
    /* Making first address default in dropdown **/
    created() {
      if (!this.selectedShippingAddressId || this.selectedShippingAddressId === 'new') {
        this.selectedShippingAddressId = this.addresses[0]?.id ?? SHIPPING_ADDRESS_NEW
      }
    },
    methods: {
      ...call('account', ['fetchAutocompleteAddresses', 'selectAutocompleteAddress']),
      ...call('feature', ['observeFlag']),
      submit() {
        this.$emit('submit', this.address)
      },
      async process(processingPromise) {
        return this.formRef.process(processingPromise)
      },
      /* In the condensed flow, we need to process the form on blur vs. button click **/
      processForm: debounce(async function () {
        const validated = await this.formRef.validate()
        if (validated) {
          this.$emit('blurAddressInput', this.address)
        }
      }, 500),
      /* Fetching autocomplete addresses only when 'address1' input field is focused
      (need to check focus state so we don't make a request if the field is filled by brower's autofill) **/
      async autocompleteAddress() {
        if (!this.observedAutocompleteFlag) {
          this.observeFlag('address-autocomplete')
          this.observedAutocompleteFlag = true
        }
        // !TODO: remove once we have tests that include autocomplete feature
        if (!env.get('CI') && this.address1RefFocused && this.isAddressAutocompleteEnabled) {
          this.autocompleteDropdownOpen = true

          try {
            const response = await this.fetchAutocompleteAddresses(this.address?.address1)
            this.suggestedAddresses = response.data
          } finally {
            this.highlightIndex = 0
          }
        }
      },
      /* Fills the input fields with the selected address from the autocomplete dropdown **/
      async selectAddress(selectedIndex) {
        try {
          const { data } = await this.selectAutocompleteAddress({
            address: this.address?.address1,
            index: selectedIndex,
          })

          const { address, city, pc, prov, country } = data.address
          const selectedCountry = this.countries.find(({ iso }) => iso === country)

          Object.assign(this.address, {
            address1: address,
            city,
            zipCode: pc,
            countryId: selectedCountry.id,
            stateId: selectedCountry.states.find(({ abbreviation }) => abbreviation === prov).id,
          })
        } finally {
          this.suggestedAddresses = []
          this.autocompleteDropdownOpen = false
        }
      },
      formattedAddress(address) {
        const { address: address1, city, pc } = address
        return `${address1}, ${city}, ${pc}`
      },
      highlightAddressIndex(direction) {
        if (this.autocompleteDropdownOpen) {
          if (direction === 'up' && this.highlightIndex > 0) {
            this.highlightIndex -= 1
          } else if (
            direction === 'down' &&
            this.highlightIndex < this.suggestedAddresses.length - 1
          ) {
            this.highlightIndex += 1
          }
          this.$refs[`suggested-address-${this.highlightIndex}`][0].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          })
        } else {
          this.autocompleteDropdownOpen = false
        }
      },
      switchKeydownEvent(e) {
        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault()
            this.highlightAddressIndex('up')
            break
          case 'ArrowDown':
            e.preventDefault()
            this.highlightAddressIndex('down')
            break
          case 'Enter':
            this.selectAddress(this.highlightIndex)
            break
          case 'Escape':
            this.autocompleteDropdownOpen = false
            break
          default:
            break
        }
      },
    },
  }
</script>

<template>
  <BaseForm
    v-slot="{ inputsEnabled, submitEnabled, errorMessage }"
    v-ref="formRef"
    spinner
    @submit="submit"
  >
    <BaseHeading v-if="$scopedSlots.default" size="h5" tag="h2" class="mb-6">
      <slot />
    </BaseHeading>

    <BasePanelWarning v-if="errorMessage" class="mb-6">
      {{ errorMessage }}
    </BasePanelWarning>

    <BaseStack>
      <BaseInput
        v-if="selectOption && shippingAddressOptions.length > 1"
        v-model="selectedShippingAddressId"
        type="select"
        name="shippingAddress"
        :options="shippingAddressOptions"
        :disabled="!inputsEnabled"
        required
        v-on="$listeners"
      >
        Shipping Address
      </BaseInput>

      <BaseStack v-if="(selectOption && selectedShippingAddressId === 'new') || !selectOption">
        <div class="flex">
          <BaseInput
            v-model="address.firstName"
            type="text"
            name="firstName"
            :disabled="!inputsEnabled"
            required
            class="w-1/2"
            v-on="$listeners"
          >
            First Name
          </BaseInput>
          <BaseInput
            v-model="address.lastName"
            type="text"
            name="lastName"
            :disabled="!inputsEnabled"
            required
            class="w-1/2 ml-2"
            v-on="$listeners"
          >
            Last Name
          </BaseInput>
        </div>
        <div class="md:flex">
          <BaseInput
            v-model="address.address1"
            role="combobox"
            aria-autocomplete="list"
            type="text"
            name="address1"
            :disabled="!inputsEnabled"
            required
            class="md:w-1/2"
            @input="autocompleteAddress"
            @keydown="switchKeydownEvent"
            @focus="address1RefFocused = true"
            @focusout="autocompleteDropdownOpen = false"
          >
            Address Line 1
          </BaseInput>
          <div
            v-if="autocompleteDropdownOpen && hasSuggestedAddresses"
            class="bg-white border border-gray-opacity68 rounded-sm absolute top-0 mt-16 w-full max-h-50 overflow-x-hidden overflow-y-auto z-20 pt-2 pb-5 shadow-md"
          >
            <ul role="listbox">
              <li
                v-for="({ preview: suggestedAddress }, addressIndex) in suggestedAddresses"
                :ref="`suggested-address-${addressIndex}`"
                :key="addressIndex"
                role="option"
                :class="`cursor-pointer p-2 pl-4 ${
                  highlightIndex === addressIndex ? 'bg-gray-opacity10' : ''
                }`"
                @mousedown.prevent
                @mouseover="highlightIndex = addressIndex"
                @click="selectAddress(addressIndex)"
              >
                {{ formattedAddress(suggestedAddress) }}
              </li>
            </ul>
            <div class="hidden" aria-live="polite" aria-atomic="true">
              {{ formattedAddress(suggestedAddresses[highlightIndex].preview) }}
            </div>
          </div>
          <BaseInput
            v-model="address.address2"
            type="text"
            name="address2"
            :disabled="!inputsEnabled"
            class="md:w-1/2 mt-2 md:mt-0 ml-0 md:ml-2"
            v-on="$listeners"
          >
            Address Line 2
          </BaseInput>
        </div>
        <div class="md:flex">
          <BaseInput
            v-model="address.city"
            type="text"
            name="city"
            :disabled="!inputsEnabled"
            required
            class="md:w-1/2"
            v-on="$listeners"
          >
            City
          </BaseInput>
          <BaseInput
            v-model="address.countryId"
            type="select"
            name="countryId"
            :options="countryOptions"
            :disabled="!inputsEnabled"
            required
            class="md:w-1/2 mt-2 md:mt-0 ml-0 md:ml-2"
            v-on="$listeners"
          >
            Country
          </BaseInput>
        </div>
        <div class="flex">
          <BaseInput
            v-model="address.stateId"
            type="select"
            name="stateId"
            :options="stateOptions"
            :disabled="!inputsEnabled || !address.countryId"
            required
            class="w-1/2"
            v-on="$listeners"
          >
            {{ selectedCountryIso === 'CA' ? 'Province' : 'State' }}
          </BaseInput>
          <BaseInputPostalCode
            v-model="address.zipCode"
            name="zipCode"
            :country-iso="selectedCountryIso"
            :disabled="!inputsEnabled"
            required
            class="w-1/2 ml-2"
          />
        </div>
      </BaseStack>
    </BaseStack>

    <BaseButton v-if="!hideButton" type="submit" :disabled="!submitEnabled" class="mt-6">
      <slot name="buttonLabel">Submit</slot>
    </BaseButton>
  </BaseForm>
</template>
